'use client'

import { Button } from '@/components/ui/button'
import { Moon, Sun } from 'lucide-react'
import { useTheme } from 'next-themes'
import { Arsenal } from 'next/font/google'
import Image from 'next/image'
import Link from 'next/link'
import { useRef, useState } from 'react'

import { NODE_ENV } from '../config'

const arsenal = Arsenal({ weight: '400', subsets: ['latin'] })
// const arsenalBold = Arsenal({ weight: '700', subsets: ['latin'] })

import logoPurple from '../assets/logo-purple.png'
// import uscLogo from '../assets/USC-DBPT.png'

function LoginButton() {
    const [showTooltip, setShowTooltip] = useState(false)
    return (
        <div className="flex flex-col items-center">
            {NODE_ENV === 'development' ? (
                <>
                    <Button variant="default" asChild>
                        <Link href="/dashboard">Log in or sign up</Link>
                    </Button>
                </>
            ) : (
                <>
                    <div
                        className={`${showTooltip ? 'opacity-100' : 'opacity-0'} inline-block px-3 py-2 mb-2 text-sm font-medium transition-opacity duration-300 bg-primaryShade rounded-lg shadow-sm tooltip`}
                    >
                        TherapyForms is coming soon! Please check again soon!
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <Button
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        Log in or sign up
                    </Button>
                </>
            )}
        </div>
    )
}

function CheckMark({ color = 'secondaryShade' }: { color?: string }) {
    return (
        <svg
            className={`w-6 h-6 text-${color} mr-2 flex-shrink-0`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
            ></path>
        </svg>
    )
}

export default function Home() {
    const playingAudio = useRef<HTMLAudioElement | null>(null)
    const { theme, setTheme } = useTheme()

    return (
        <main>
            <div className="max-h-[768px] w-full px-8">
                <div className="mx-auto max-w-screen-lg min-h-screen py-6 flex flex-col justify-around text-center">
                    <div className="mx-auto my-8 sm:my-12">
                        <Image src={logoPurple} alt="Therapy Forms" width={150} />
                    </div>
                    <div className={`text-4xl mb-12 ${arsenal.className}`}>Therapy Forms</div>
                    <div className="max-w-lg mx-auto leading-relaxed font-semibold mb-4">
                        Turn any intake form into a modern, secure, accessible web form
                    </div>
                    <div className="max-w-lg mx-auto leading-relaxed text-sm">
                        Save time, save money, and provide a dramatically improved intake experience
                        for your clients and admin staff
                    </div>
                    <div className="mt-10">
                        <LoginButton />
                    </div>
                    <div className="my-20">
                        <h3 className={`text-4xl font-semibold mb-8 ${arsenal.className}`}>
                            Features
                        </h3>
                        <ul className="space-y-3 text-left max-w-lg mx-auto">
                            <li className="flex items-start">
                                <CheckMark />
                                <span>Accepts any PDF</span>
                            </li>
                            <li className="flex items-start">
                                <CheckMark />
                                <span>
                                    Returns an accessible, user-friendly web-form{' '}
                                    <a
                                        target="_blank"
                                        className="text-primary underline cursor-pointer"
                                        onClick={() => {
                                            if (playingAudio.current) {
                                                playingAudio.current.pause()
                                            }
                                            playingAudio.current = new Audio(
                                                'https://example-data.therapy-forms.com/english-example.mp3'
                                            )
                                            playingAudio.current.play().catch((error) => {
                                                if (
                                                    error.name !== 'NotAllowedError' &&
                                                    error.name !== 'AbortError'
                                                ) {
                                                    console.error('Error playing audio', { error })
                                                }
                                            })
                                        }}
                                    >
                                        with audio
                                    </a>
                                </span>
                            </li>
                            <li className="flex items-start">
                                <CheckMark />
                                <span>
                                    Automatic translation into{' '}
                                    <a
                                        target="_blank"
                                        className="text-primary underline cursor-pointer"
                                        onClick={() => {
                                            if (playingAudio.current) {
                                                playingAudio.current.pause()
                                            }
                                            playingAudio.current = new Audio(
                                                'https://example-data.therapy-forms.com/german-example.mp3'
                                            )
                                            playingAudio.current.play().catch((error) => {
                                                if (
                                                    error.name !== 'NotAllowedError' &&
                                                    error.name !== 'AbortError'
                                                ) {
                                                    console.error('Error playing audio', {
                                                        error,
                                                    })
                                                }
                                            })
                                        }}
                                    >
                                        hundreds of languages
                                    </a>
                                </span>
                            </li>
                            <li className="flex items-start">
                                <CheckMark />
                                <span>Send SMS or email links to your secure form</span>
                            </li>
                            <li className="flex items-start">
                                <CheckMark />
                                <span>
                                    Receive completed images as if the user filled out the original
                                    form
                                </span>
                            </li>
                            <li className="flex items-start">
                                <CheckMark />
                                <span>Integration with EMRs available (eg: webPT!)</span>
                            </li>
                        </ul>
                    </div>
                    <div className="max-w-lg mx-auto my-8">
                        <div className="leading-loose">
                            <span className="text-primaryShade font-semibold">TherapyForms</span>{' '}
                            uses AI to convert any intake form paperwork into beautiful, accessible
                            web forms, and provides a HIPAA-compliant platform for clinics to
                            collect patient information.
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="max-w-xlg mx-auto">
                        {[
                            {
                                title: 'Home-health',
                                features: ['3 users', '10 forms', 'Unlimited sends'],
                                price: 150,
                                additionalInfo: '+ messaging fees',
                            },
                            {
                                title: 'Clinical',
                                features: ['20 users', 'Unlimited forms', 'EMR integration'],
                                price: 500,
                                additionalInfo: '+ messaging fees',
                            },
                            {
                                title: 'Institutional',
                                features: [
                                    'Unlimited users',
                                    'Unlimited forms',
                                    'Priority support',
                                ],
                                price: 2500,
                                additionalInfo: '+ messaging fees',
                            },
                        ].map((plan, index) => (
                            <div
                                key={index}
                                className="rounded-lg p-6 transition-all duration-300 flex flex-col md:flex-row h-full m-4 md:m-10 shadow-lg"
                            >
                                <h4
                                    className={`text-2xl font-semibold mb-4 text-left ${arsenal.className} md:mr-6 min-w-32`}
                                >
                                    {plan.title}
                                </h4>
                                <ul className="md:mb-6 md:mt-0 mt-4 flex-grow md:mr-6">
                                    {plan.features.map((feature, featureIndex) => (
                                        <li key={featureIndex} className="flex items-start mb-3">
                                            <CheckMark />
                                            <span className="text-sm md:text-base">{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                <div className="mt-2 md:mt-auto">
                                    <div
                                        className={`text-2xl md:text-3xl mb-2 text-center ${arsenal.className}`}
                                    >
                                        ${plan.price}
                                        <span className="text-sm font-normal">/month</span>
                                    </div>
                                    <div className="text-sm text-gray-500 text-center">
                                        {plan.additionalInfo}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-20">
                        <div className="max-w-lg text-sm mx-auto leading-loose">
                            <span className="text-xl">Our Mission</span>
                            <br />
                            Help healthcare providers provide world-class patient care while
                            spending less time doing paperwork.
                            <br />
                            <br />
                            <br />
                            <span className="text-xl">About us</span>
                            <br />
                            <span className="text-primaryShade font-semibold">
                                TherapyForms
                            </span>{' '}
                            was founded by Andrea Mooy and her husband Seandon Mooy.
                            <br />
                            <br />
                            Andrea worked as a PT aide and front-desk employee before obtaining her
                            Doctorate in Physical Therapy from the{' '}
                            <span className="font-semibold">
                                USC Division of Biokinesiology and Physical Therapy
                            </span>{' '}
                            and becoming a licensed Physical Therapist. She has spent an inordinate
                            amount of time helping clinics improve their patient intake flow.
                            <br />
                            <br />
                            Seandon is a former <span className="font-semibold">
                                YCombinator
                            </span>{' '}
                            founder and senior software engineer with experienced in secure
                            health-care infrastructure. He loves security, uptime, reliability, and
                            solving his wife&apos;s problems.
                            <br />
                            <br />
                            <br />
                            <span className="text-xl">On AI in healthcare</span>
                            <br />
                            AI can do many things, but it&apos;s no replacement for human
                            interaction or quality healthcare. We use AI to build simple, easy to
                            use tools that save healthcare provider&apos;s time and money{' '}
                            <span className="italic">
                                by automating the boring stuff
                            </span>.{' '}
                            <span className="font-semibold">
                                No AI is ever used when handling user data
                            </span>
                            , only when pre-processing your blank forms. Security and respect for
                            user privacy is a core promise, not an afterthought.
                        </div>
                        {/* <Image src={uscLogo} alt="USC" width={250} className="mx-auto mt-12" /> */}
                    </div>
                    <div className="my-4">
                        <LoginButton />
                    </div>
                    <div className="text-sm text-muted-foreground text-center my-10 flex justify-center items-center">
                        <div>
                            Built in Pasadena, CA | &copy; {new Date().getFullYear()} TherapyForms
                            Inc.
                        </div>
                        <Button
                            variant="muted"
                            size="icon"
                            onClick={() => {
                                setTheme(theme === 'dark' ? 'light' : 'dark')
                            }}
                            className="text-muted-foreground h-5 w-5 ml-1"
                        >
                            {theme === 'dark' ? <Moon /> : <Sun />}
                        </Button>
                    </div>
                </div>
            </div>
        </main>
    )
}
